import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import StripeProvider from './StripeProvider';
import CheckoutForm from './CheckoutForm';
import { getPaymentInfo, generateClientSecret } from './api/request/Payment';
import {
  TextField,
  Box,
  Typography,
  InputAdornment,
  Grid,
  CircularProgress,
  Alert
} from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';


function App() {

  const { param } = useParams();
  const [connectAccountId, setConnectAccountId] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [amount, setAmount] = useState("");
  const [returnUrl, setReturnUrl] = useState("");
  const [amountError, setAmountError] = useState(false);


  const [row, setRow] = useState({
    price: "",
    description: "",
    amount: "",
    currency: "",
    customerAdd: false,
    business_name: "",
  });

  const [account, setAccount] = useState("");


  useEffect(() => {
    getPaymentInfo(param)
      .then(({ data: response }) => {
        // console.log(response);
        setConnectAccountId(response.data.connect);
        setClientSecret(response.data.token);
        setRow(response.data);
        setAccount(response.account);
        if (response.data.return_url) {
          setReturnUrl(response.data.return_url);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        const response = err.response;
        if (response === null || response === undefined) return;

        if (response.status === 404) {
          setError(response.data.message);
        }

        // handle server error
        if (response.status === 500) {
          setError(response.data.message);
        }
      });
  }, [param]);

  const handleAmountChange = (event) => {
    const value = event.target.value.replace(/[^\d.]/g, '');

    if (isValidAmount(value)) {
      setAmount(value);
    }
  };

  const formatAmount = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? '0.00' : number.toFixed(2);
  };

  const isValidAmount = (value) => {
    const regex = /^\d*\.?\d{0,2}$/;
    return regex.test(value);
  };

  const handleBlur = () => {
    setAmount((prevAmount) => formatAmount(prevAmount));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const value = event.target.value;
      console.log(value);
      if (value <= 0) {
        setAmountError(true);
        return;
      }

      setAmountError(false);
      setAmount(value);

      generateClientSecret({ amount: formatAmount(value) * 100, curreny: row.currency, account: row.connect })
        .then(({ data: response }) => {
          console.log(response);
          setClientSecret(response.data.token);
        })
        .catch((err) => {
          console.log("Error Occured", err);
        });

    }
  };


  if (loading) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Use full height of the viewport
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20vh', // Use full height of the viewport
      }}>
        <Alert severity="error">
          Something went wrong.
        </Alert>
      </Box>
    );
  }

  return (
    <Box bgcolor='#f0f2f5' sx={{ flexGrow: 1, height: '100vh' }} >
      <Grid container spacing={2}>
        <Grid xs={12} height={{ sx: "100%", sm: '100vh' }} sm={6} mb={{ xs: '0.5', sm: 0, md: 0 }} p={{ xs: 10, sm: 20, md: 20 }} pt={{ xs: 5, sm: 10, md: 15 }} textAlign={{ xs: 'center', sm: 'left' }}>
          <Box height={{ sx: "100%", sm: '80vh' }}>
            <Typography variant="h2" component="h1" mb={3} color='#1a1a1a'>
              {row.business_name}
            </Typography>
            <Typography variant="h6" component="h6" mb={1} color='#1a1a1a99'>
              Pay {row.business_name}
            </Typography>
            {row.amount && (
              <Typography variant="h2" component="h2" color='#000' fontSize={'2rem'} mb={3}>
                {row.currency} {row.amount}
              </Typography>
            )}
            {row.amount == null || row.amount == "" && (
              <Typography variant="h2" component="h2" color='#000' fontSize={'2rem'} mb={3}>
                <TextField
                  error={(amountError) ? true : false}
                  value={amount}
                  onChange={handleAmountChange}
                  onBlur={handleBlur}
                  variant="standard"
                  placeholder='E.g 10.00'
                  helperText="Write an amount and press Enter"
                  onKeyPress={handleKeyPress}
                  inputProps={{
                    style: {
                      fontSize: '2rem',
                      color: '#000',
                      padding: 0,
                      border: 'none',
                      background: 'none',
                      // width: `${String(amount).length + 1}ch`,
                      textAlign: 'left'
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: <InputAdornment position="start">{row.currency}</InputAdornment>,
                  }}
                />
              </Typography>
            )}

            <Typography variant="p" component="p" color='#000'>
              {row.description}
            </Typography>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Typography variant="p" component="p" color='#1a1a1a99'>
              Copyright &copy; xonder.co.uk
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} sm={6} bgcolor='#fff' height={{ sx: "100%", sm: '100vh' }} p={{ xs: 10, sm: 20, md: 20 }} pt={{ xs: 5, sm: 10, md: 15 }} textAlign={{ xs: 'center', sm: 'left' }}>
          <Box >
            <StripeProvider clientSecret={clientSecret} connectAccountId={connectAccountId} >
              <CheckoutForm returnUrl={returnUrl} />
            </StripeProvider>
          </Box>
          <Box sx={{ display: { xs: 'block', md: 'none' }, mt: { xs: 3 } }}>
            <Typography variant="p" component="p" color='#1a1a1a99'>
              Copyright &copy; xonder.co.uk
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>

  );
}

export default App;
