import React, { useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
    Box,
    Typography,
    CircularProgress
} from "@mui/material";

const pubKey = process.env.REACT_APP_STRIPE_KEY;

const StripeProvider = ({ children, clientSecret, connectAccountId }) => {

    const stripePromise = useMemo(() => {
        return loadStripe(pubKey, {
            stripeAccount: connectAccountId,
        });
    }, [connectAccountId]);

    const appearance = {
        theme: 'stripe'

    };

    const options = {
        clientSecret,
        appearance,
    };

    if (!clientSecret) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'top',
                height: '100vh', // Use full height of the viewport
            }}>
                <Typography variant="h2" component="h2" color='#000' fontSize={'2rem'} mb={3}>
                    Enter amount first
                </Typography>
            </Box>
        );
    }

    return (
        <Elements options={options} stripe={stripePromise}>
            {children}
        </Elements>
    );
};

export default StripeProvider;
