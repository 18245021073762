import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {
    PaymentElement,
    useStripe,
    useElements,
    ExpressCheckoutElement
} from '@stripe/react-stripe-js';
import { Button, CircularProgress, Box, Divider, Alert } from '@mui/material';
import { blue } from '@mui/material/colors';
import { submitPayment } from './api/request/Payment';

const baseUrl = process.env.REACT_APP_BASE_URL;

const CheckoutForm = ({ returnUrl }) => {
    const stripe = useStripe();
    const elements = useElements();
    const params = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [showCard, setShowCard] = useState(true);
    const [message, setMessage] = useState(null);
    const [aletType, setAlertType] = useState('');
    const [stripePaymentId, setStripePaymentId] = useState('');

    const generatedToken = params.id;

    const paymentElementOptions = {
        layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: false,
            spacedAccordionItems: true
        }
    };

    const ExpressCheckoutOptions = {
        layout: {
            overflow: 'never'
        }
    }

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: baseUrl + generatedToken,
            },
            redirect: "if_required"
        });

        console.log(error);

        if (!error) {
            switch (paymentIntent.status) {
                case "succeeded":
                    setAlertType('success');
                    setStripePaymentId(paymentIntent.id);
                    setMessage("Payment succeeded!");
                    setShowCard(false);
                    console.log(returnUrl);
                    if (returnUrl) {
                        window.location.href = returnUrl;
                    }
                    break;
                case "processing":
                    setAlertType('info');
                    setMessage("Your payment is processing.");
                    setShowCard(false);
                    break;
                case "requires_payment_method":
                    setAlertType('warning');
                    setMessage("Your payment was not successful, please try again.");
                    setShowCard(false);
                    break;
                default:
                    setAlertType('error');
                    setMessage("Something went wrong.");
                    setShowCard(false);
                    break;
            }
        } else {
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error occurred.");
            }
        }

        setIsLoading(false);
    };

    const onConfirm = async (event) => {
        if (!stripe) {
            // Stripe.js hasn't loaded yet.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error: submitError } = await elements.submit();
        if (submitError) {
            console.log(submitError.message);
            return;
        }

        // Confirm the PaymentIntent using the details collected by the Express Checkout Element
        const { error, paymentIntent } = await stripe.confirmPayment({
            // `elements` instance used to create the Express Checkout Element
            elements,
            confirmParams: {
                return_url: baseUrl + generatedToken,
            },
            redirect: "if_required"
        });

        if (error) {
            console.log(error.message);
            setMessage(error.message);
        } else {
            switch (paymentIntent.status) {
                case "succeeded":
                    setAlertType('success');
                    setStripePaymentId(paymentIntent.id);
                    setMessage("Payment succeeded!");
                    setShowCard(false);
                    if (returnUrl) {
                        window.location.href = returnUrl;
                    }
                    break;
                case "processing":
                    setAlertType('info');
                    setMessage("Your payment is processing.");
                    setShowCard(false);
                    break;
                case "requires_payment_method":
                    setAlertType('warning');
                    setMessage("Your payment was not successful, please try again.");
                    setShowCard(false);
                    break;
                default:
                    setAlertType('error');
                    setMessage("Something went wrong.");
                    setShowCard(false);
                    break;
            }
        }
    };

    // useEffect(() => {
    //     const data = {
    //         stripeToken: stripePaymentId,
    //         token: generatedToken,
    //         email: '',
    //         amount: newAmount,
    //     };
    //     submitPayment(data)
    //         .then(({ data: response }) => {
    //             setShowCard(false);
    //             if (response.redirectUrl !== undefined && response.redirectUrl !== "") {
    //                 window.location.href = response.redirectUrl;
    //             }
    //         })
    //         .catch((err) => {

    //         });
    // });

    // const submitPaymet = () => {

    // };

    return (
        <div>
            {showCard && (
                <form id="payment-form" onSubmit={handleSubmit}>
                    <Box mb={3}>
                        <ExpressCheckoutElement options={ExpressCheckoutOptions} onConfirm={onConfirm} />
                    </Box>
                    <Divider sx={{ heigh: 'auto' }} textAlign="center">
                        OR PAY BY CARD
                    </Divider>
                    <Box mt={3}>
                        <PaymentElement id="payment-element" options={paymentElementOptions} />
                    </Box>

                    <Box mt={3} sx={{ position: 'relative' }} >
                        <Button type="submit" id="submit" disabled={isLoading || !stripe || !elements} variant="contained" fullWidth >
                            Pay now
                        </Button>
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: blue[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </form>
            )}

            {!showCard && (
                message && <Alert severity={aletType}>
                    {message}
                </Alert>
            )}
        </div>
    );
};

export default CheckoutForm;
