import Api from "../api";
import csrf from "../csrf";

const PAYMENT_URL = "api/payments";

const getPaymentInfo = async (token) => {
    await csrf.getCookie();
    return await Api.get(`${PAYMENT_URL}/link-info/${token}`);
};

const submitPayment = async (data) => {
    await csrf.getCookie();
    return await Api.post(`${PAYMENT_URL}/submit-payment`, data);
};

const generateClientSecret = async (data) => {
    await csrf.getCookie();
    return await Api.post(`${PAYMENT_URL}/token`, data);
};

export {
    getPaymentInfo,
    submitPayment,
    generateClientSecret,
};
