import axios from "axios";

const Api = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // server API's URL
});

Api.defaults.withCredentials = true;
Api.defaults.headers.common["Accept"] = "application/json";
Api.defaults.headers.common["Content-Type"] = "application/json";

Api.interceptors.request.use(
    function (config) {
        if (sessionStorage.getItem("token")) {
            const setconf = (config.headers["Authorization"] =
                "Bearer " + sessionStorage.getItem("token"));
            //console.log("set conf auth: ", setconf);
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default Api;
