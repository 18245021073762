import Api from "./api";
import Cookie from "js-cookie";

export default {
    getCookie() {
        let token = Cookie.get("XSRF-TOKEN");
        if (token) {
            //console.log(token);
            return new Promise((resolve) => {
                resolve(token);
            });
        }
        return Api.get("sanctum/csrf-cookie");
    },
};
